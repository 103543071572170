import * as styled from './styles.js';

const BannerItem = ({ text, font, color, backgroundColor }) => {
	return (
		<styled.BannerContentWrapper
			style={{
				backgroundColor: backgroundColor,
			}}
		>
			<styled.BannerContent style={{ fontFamily: font, color: color }}>
				{text}
			</styled.BannerContent>
		</styled.BannerContentWrapper>
	);
};
export default BannerItem;
