import { styled } from 'styled-components';

export const Nav = styled.nav`
	min-width: 200px;
`;

export const NavMenu = styled.ul`
	display: flex;
	justify-content: space-around;
`;

export const NavItem = styled.li`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${(props) => props.theme.colors.white};
`;

export const CartButton = styled.div`
	min-height: 15px;
	min-width: 15px;

	cursor: pointer;

	position: relative;
`;

export const CartCountIcon = styled.span`
	position: absolute;
	left: -10px;
	top: -15px;

	width: 1.2rem;
	height: 1.2rem;

	font-size: 0.75rem;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	border: 1.5px solid ${(props) => props.theme.colors.white};

	background-color: ${(props) => props.theme.colors.chredOpaque};
	color: ${(props) => props.theme.colors.white};
`;
