import * as styled from './styles.js';
import { Link } from 'react-router-dom';

const NavBar = () => {
	return (
		<styled.Nav aria-label='top navigation bar'>
			<styled.NavMenu>
				<Link to={'/'}>
					<styled.NavItem>Home</styled.NavItem>
				</Link>
				<Link to={'/about'}>
					<styled.NavItem>About</styled.NavItem>
				</Link>
				<Link to={'/shop'}>
					<styled.NavItem>Shop</styled.NavItem>
				</Link>
			</styled.NavMenu>
		</styled.Nav>
	);
};
export default NavBar;
