import styled from 'styled-components';

export const ReviewWrapper = styled.section`
	height: 100%;
	max-height: 400px;
	width: 100%;
	max-width: 900px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${(props) => props.theme.colors.whiteOpaque};
`;
