import * as styled from './styles.js';
import LandingCarousel from '../LandingCarousel/LandingCarousel.jsx';
import Featured from '../Featured/Featured.jsx';
import Reviews from '../Reviews/Reviews.jsx';
// import Trending from '../Trending/Trending.jsx';
// import PodcastCTA from '../CallToAction/Podcast/PodcastCTA.jsx';

const Homepage = () => {
	return (
		<styled.HomeWrapper>
			<LandingCarousel />
			<styled.ReviewWrapper>
				<Reviews />
			</styled.ReviewWrapper>
			<styled.FeaturedWrapper>
				<Featured />
			</styled.FeaturedWrapper>
			{/* <styled.CallToAction>
				<PodcastCTA />
			</styled.CallToAction> */}
			{/* <styled.TrendingWrapper>
				<Trending />
			</styled.TrendingWrapper> */}
		</styled.HomeWrapper>
	);
};
export default Homepage;
