import { Link } from 'react-router-dom';
import * as styled from './styles.js';

const Footer = () => {
	return (
		<styled.Footer>
			<styled.FooterCTANav aria-label='Bottom of the Page Navigation Bar'>
				<styled.FooterCTAList>
					<styled.FooterCTAListItem>
						<styled.FooterCTAText>
							Shop All The Latest Trends And Styles:
						</styled.FooterCTAText>
						<Link to={'/shop'}>
							<styled.NavItem>SHOP</styled.NavItem>
						</Link>
					</styled.FooterCTAListItem>
					<styled.FooterCTAListItem>
						<styled.FooterCTAText>
							Learn More About The Way We Work And Our Mission:
						</styled.FooterCTAText>
						<Link to={'/about'}>
							<styled.NavItem>About</styled.NavItem>
						</Link>
					</styled.FooterCTAListItem>
					<styled.FooterCTAListItem>
						<styled.FooterCTAText>
							Stay In Touch. Contact Us Today!
						</styled.FooterCTAText>
						<Link to={'/contact'}>
							<styled.NavItem>Contact</styled.NavItem>
						</Link>
					</styled.FooterCTAListItem>
				</styled.FooterCTAList>
			</styled.FooterCTANav>
			<styled.CopyRight>
				&copy; | 2023 Everything Kansas City | All Rights Reserved
			</styled.CopyRight>
		</styled.Footer>
	);
};
export default Footer;
