import styled from 'styled-components';

export const ReviewModalForm = styled.form`
	height: 90%;
	width: 90%;
	margin: 15px;
	padding: 20px;
	background-color: #f5f5f5;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 600px) {
		padding: 10px;
	}
`;

export const ReviewModalLabel = styled.label`
	font-size: 1.1rem;
	margin-bottom: 5px;
	color: #333;

	@media (max-width: 600px) {
		font-size: 1rem;
	}
`;

export const ReviewModalInput = styled.input`
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 15px;
	font-size: 1rem;

	&:focus {
		border-color: #0077b6;
		outline: none;
		box-shadow: 0 0 5px rgba(0, 119, 182, 0.5);
	}
`;

export const ReviewModalTextArea = styled.textarea`
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	height: 120px;
	margin-bottom: 15px;
	font-size: 1rem;
	resize: vertical;

	&:focus {
		border-color: #0077b6;
		box-shadow: 0 0 5px rgba(0, 119, 182, 0.5);
	}
`;

export const BaseStar = styled.span`
	font-size: 1.5rem;
	margin: 0 5px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.grey};
`;

export const StarPicker = styled.div`
	display: flex;

	${BaseStar}:hover,
	${BaseStar}:hover ~ ${BaseStar} {
		color: ${(props) => props.theme.colors.chgold};
	}
`;

export const Star = styled(BaseStar)``;

export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-top: 10px;

	@media (max-width: 600px) {
		flex-direction: column;
	}
`;

export const Button = styled.button`
	flex: 1;
	padding: 10px;
	font-size: 1rem;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s;

	&:first-child {
		background-color: ${(props) => props.theme.colors.chred};
		color: ${(props) => props.theme.colors.white};

		&:hover {
			background-color: ${(props) => props.theme.colors.chredOpaque};
		}
	}

	&:last-child {
		background-color: ${(props) => props.theme.colors.rblue};
		color: ${(props) => props.theme.colors.white};

		&:hover {
			background-color: ${(props) => props.theme.colors.rblue2};
		}
	}

	@media (max-width: 600px) {
		margin-bottom: 10px;
	}
`;
