import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import {
	useCreateNewReviewMutation,
	// useGetLatestReviewsQuery,
} from '../../../Assets/Slices/apiSlice';
import * as DOMPurify from 'dompurify';
import * as styled from './styles.js';

ReactModal.setAppElement('#root');

const ReviewModal = ({ productId, isOpen, onRequestClose, setIsModalOpen }) => {
	const [name, setName] = useState();
	const [title, setTitle] = useState();
	const [reviewStatement, setReviewStatement] = useState();
	const [starCount, setStarCount] = useState(0);
	const [createNewReview, { isLoading }] = useCreateNewReviewMutation();
	// trying to re hydrate just reviews when new review is created
	// const { refetch: refetchLatestReviews } = useGetLatestReviewsQuery();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const [hoverValue, setHoverValue] = useState(0);

	const canSave =
		[title, name, reviewStatement, starCount].every(Boolean) && !isLoading;

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	let modalStyles = {
		content: {
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			padding: '0',
			height: '60%',
			width: '60%',
			overflow: 'hidden',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	windowWidth >= 800
		? (modalStyles = {
				content: {
					...modalStyles.content,
				},
		  })
		: (modalStyles = {
				content: {
					...modalStyles.content,
					top: '60%',
					height: '80%',
					width: '90%',
				},
		  });

	windowHeight >= 601
		? (modalStyles = {
				content: {
					...modalStyles.content,
				},
		  })
		: (modalStyles = {
				content: {
					...modalStyles.content,
					height: '75%',
					top: '62%',
				},
		  });

	const resetValues = () => {
		setName('');
		setReviewStatement('');
		setTitle('');
		setStarCount(0);
	};

	const cleanReview = (title, name, reviewStatement) => {
		const cleanTitle = DOMPurify.sanitize(title);
		const cleanName = DOMPurify.sanitize(name);
		const cleanReviewStatement = DOMPurify.sanitize(reviewStatement);

		return { cleanTitle, cleanName, cleanReviewStatement };
	};

	const formatData = (data) => {
		return {
			name: data.cleanName,
			title: data.cleanTitle,
			reviewStatement: data.cleanReviewStatement,
			starCount,
			productId,
		};
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (canSave) {
			let cleanedReview = cleanReview(title, name, reviewStatement);
			const formattedReview = formatData(cleanedReview);
			try {
				resetValues();
				onRequestClose();
				await createNewReview({ ...formattedReview }).unwrap();
				// refetchLatestReviews();
			} catch (error) {
				console.log('error with post request to /review/new:', error);
			}
		}
	};

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={() => setIsModalOpen(false)}
			style={modalStyles}
		>
			<styled.ReviewModalForm>
				<styled.StarPicker>
					{Array(5)
						.fill(null)
						.map((_, index) => (
							<styled.Star
								key={index}
								onMouseEnter={() => setHoverValue(index + 1)}
								onMouseLeave={() => setHoverValue(0)}
								onClick={() => setStarCount(index + 1)}
								style={{
									color: index < (hoverValue || starCount) ? 'gold' : 'grey',
								}}
							>
								★
							</styled.Star>
						))}
				</styled.StarPicker>

				<styled.ReviewModalLabel htmlFor='name'>Name:</styled.ReviewModalLabel>
				<styled.ReviewModalInput
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder='Your Name'
				/>

				<styled.ReviewModalLabel htmlFor='title'>Title</styled.ReviewModalLabel>
				<styled.ReviewModalInput
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					placeholder='AN AWESOME PRODUCT!'
				/>

				<styled.ReviewModalLabel htmlFor='reviewStatement'>
					Statement
				</styled.ReviewModalLabel>
				<styled.ReviewModalTextArea
					value={reviewStatement}
					onChange={(e) => setReviewStatement(e.target.value)}
					placeholder="Say what's on your mind."
					maxLength={350}
				/>
				<styled.ButtonWrapper>
					<styled.Button
						onClick={() => {
							resetValues();
							setIsModalOpen(false);
						}}
					>
						Cancel
					</styled.Button>
					<styled.Button
						disabled={canSave ? false : true}
						type='submit'
						onClick={handleSubmit}
					>
						Submit
					</styled.Button>
				</styled.ButtonWrapper>
			</styled.ReviewModalForm>
		</ReactModal>
	);
};
export default ReviewModal;
