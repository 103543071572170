import * as styled from './styles.js';
import { Link } from 'react-router-dom';
import { IoMdStarOutline, IoMdStar, IoMdStarHalf } from 'react-icons/io';

const ReviewCard = ({ slug, name, date, title, review, stars }) => {
	// console.log('ReviewCard Rendered');
	const starFactory = (stars) => {
		const fullStars = Math.floor(stars);
		const halfStar = stars - fullStars >= 0.5;
		const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

		let icons = [];

		for (let i = 0; i < fullStars; i++) {
			icons.push(<IoMdStar key={`full-${i}`} />);
		}

		if (halfStar) {
			icons.push(<IoMdStarHalf key='half' />);
		}

		for (let i = 0; i < emptyStars; i++) {
			icons.push(<IoMdStarOutline key={`empty-${i}`} />);
		}

		return icons;
	};

	return (
		<styled.ReviewCardWrapper>
			<Link to={`/shop/${slug}`}>
				<styled.ReviewTitle>{title}</styled.ReviewTitle>
				<styled.Date>{new Date(date).toLocaleDateString()}</styled.Date>
				<styled.UserName>{name}</styled.UserName>
				<styled.ReviewStatement>
					{review.slice(0, 200)}...
				</styled.ReviewStatement>
				<styled.Stars>{starFactory(stars).map((star) => star)}</styled.Stars>
			</Link>
		</styled.ReviewCardWrapper>
	);
};
export default ReviewCard;
