import styled from 'styled-components';

export const ShopCard = styled.div`
	border: 1px solid #e1e1e1;
	background-color: ${(props) => props.theme.colors.white};
	border-radius: 15px;
	box-shadow: 0 0 5px 0.05px ${(props) => props.theme.colors.greyOpaque};
	transition: all 0.2s;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:hover {
		transform: scale(1.05);
		box-shadow: -3px 3px 5px 1px ${(props) => props.theme.colors.greyOpaque};
	}
`;

export const ShopCardHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin: 5px;
	width: 95%;
`;

export const ShopCardTitle = styled.h3`
	color: ${(props) => props.theme.colors.grey};
`;

export const ShopCardPrice = styled.span`
	color: #e91e63;

	font-weight: bold;

	display: block;

	margin: 10px 0;
`;

export const ShopCardImg = styled.img`
	width: clamp(180px, 80%, 230px);
	height: 200px;

	object-fit: cover;
	object-position: center;

	margin-bottom: 15px;

	&:hover {
		content: url(${(props) => props.hoverImg});
	}
`;

export const SizeOptions = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> select,
	select:active {
		border: none;
	}
`;

export const ATCButtonWrapper = styled.div`
	width: 100%;
	padding: 15px 10px;

	display: flex;
	justify-content: flex-end;

	button {
		transition: all 0.2s ease-in;

		&:hover {
			transform: scale(1.05);
		}
	}
`;
