import styled from 'styled-components';

export const ProductWrapper = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ProductView = styled.div`
	width: 100%;
	max-width: 900px;
	padding: 10px;
	margin-bottom: 15px;

	display: flex;

	@media (max-width: 768px) {
		max-height: none;
		flex-direction: column;
	}
`;

export const ProductDisplayImgWrapper = styled.div`
	height: 80%;
	width: 65%;

	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		width: 80%;
		border-radius: 10px;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
		margin: 5px;

		object-fit: cover;

		@media (max-width: 768px) {
			width: 90%;
			justify-content: center;
		}
	}

	@media (max-width: 768px) {
		width: 90%;
		flex-direction: row;
		margin-bottom: 15px;
	}
`;

export const ImgNavWrapper = styled.div`
	width: 100%;
	margin: 15px 0;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	img {
		height: 50px;
		width: 50px;
		border-radius: 10px;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
		margin-right: 15px;
		cursor: pointer;
	}
`;

export const ProductInfoWrapper = styled.div`
	max-height: 100%;
	width: 35%;

	display: flex;
	flex-direction: column;

	@media (max-width: 768px) {
		width: 100%;
		align-items: center;
		margin-bottom: 15px;
	}
`;

export const ProductTitle = styled.h2`
	font-size: 1.5rem;
	color: #333;
`;

export const ProductDescription = styled.p`
	font-size: 16px;
	color: #777;
	margin-bottom: 20px;
`;

export const ProductReviewComponentWrapper = styled.div`
	margin-bottom: 20px;
	@media (max-width: 768px) {
		width: 90%;
	}
`;

export const ProductPrice = styled.span`
	display: block;
	font-size: 20px;
	color: #e91e63;
`;

export const QuantityPickerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	margin-bottom: 20px;

	@media (max-width: 768px) {
		justify-content: center;
		width: 100%;
	}
`;

export const SizeOptions = styled.form`
	height: 40px;
	margin-bottom: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	> select,
	select:active {
		border: none;
	}
`;

export const ATCButtonWrapper = styled.div`
	height: 50px;
	width: 100%;

	@media (max-width: 768px) {
		width: 60%;
	}
`;

export const IconWrapper = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-around;
`;

export const Icon = styled.div`
	cursor: pointer;

	transition: all 0.35s ease-in;

	&:hover {
		transform: scale(1.5);
	}

	&:active {
		transform: scale(0.7);
	}
`;
