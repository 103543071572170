import styled from 'styled-components';

export const ProductReviewWrapper = styled.section`
	height: 100%;
	position: relative;
`;

export const AvgCountWrapper = styled.div`
	width: 100%;
	padding: 5px;

	display: flex;

	background-color: ${(props) => props.theme.colors.chred};
`;

export const ProductReviewAvgStar = styled.div`
	margin-right: 15px;

	& > * {
		color: ${(props) => props.theme.colors.chgold};
	}
`;

export const ReviewCount = styled.span`
	color: ${(props) => props.theme.colors.chgold};
`;

export const DropDownIcon = styled.div`
	margin-left: auto;
	height: 100%;

	color: ${(props) => props.theme.colors.chgold};

	display: flex;
	align-items: center;

	cursor: pointer;
`;

export const ReviewDropDownWrapper = styled.div`
	width: 100%;
	height: ${(props) => (props.isopen ? '100%' : '0')};
	max-height: 300px;
	transition: height 0.25s ease-in-out, opacity 0.3s ease-in-out;

	display: flex;
	flex-direction: column;
	/* align-items: center; */

	overflow-y: scroll;

	opacity: ${(props) => (props.isopen ? '1' : '0')};
	visibility: ${(props) => (props.isopen ? 'visible' : 'hidden')};

	& > * {
		margin-bottom: 5px;
	}

	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${(props) => props.theme.colors.credOpaque};
		border-radius: 6px;
		border: 2px solid transparent;

		background-clip: content-box;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(50, 50, 50, 0.7);
	}

	&::-webkit-scrollbar-thumb:active {
		background-color: rgba(0, 0, 0, 0.9);
	}

	scrollbar-width: thin;
	scrollbar-color: ${(props) => props.theme.colors.chredOpaque};
`;

export const WriteAReview = styled.div`
	width: 50%;
	margin: 5px 0;
	padding: 5px 0;

	border-radius: 15px;

	background-color: ${(props) => props.theme.colors.sgrey};

	cursor: pointer;

	text-align: center;
	align-self: center;
	color: ${(props) => props.theme.colors.rblue};

	transition: all 0.25s ease-in;

	&:hover {
		transform: scale(1.1);
		color: ${(props) => props.theme.colors.chgold};
		background-color: ${(props) => props.theme.colors.chred};
	}

	&:active {
		transform: scale(0.9);
		color: ${(props) => props.theme.colors.chgold};
		background-color: ${(props) => props.theme.colors.chredOpaque};
	}
`;

export const NoReviews = styled.p``;

export const UserName = styled.h4`
	border-top: 1px solid ${(props) => props.theme.colors.sgrey};
`;

export const Date = styled.p`
	width: 100%;
`;

export const Stars = styled.div`
	margin-bottom: 3px;
`;

export const ReviewTitle = styled.h3``;

export const ReviewStatement = styled.p`
	text-align: left;
`;
