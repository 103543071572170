import * as styled from './styles.js';
import { formatTotalItemPrice } from '../../../Assets/Helpers/formatPrice.js';

import { LiaChevronLeftSolid, LiaChevronRightSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import {
	modifyItemObject,
	removeItem,
} from '../../../Assets/Slices/cartSlice.js';

const CartItemCard = ({ id, title, desc, price, img, quantity, size }) => {
	const dispatch = useDispatch();
	const cartItemObject = useSelector((state) =>
		state.cart.itemObjectArray.find(
			(product) => product.id === id && product.size === size
		)
	);

	const decrement = () => {
		if (quantity > 1) {
			dispatch(
				modifyItemObject({
					product: { ...cartItemObject },
					quantity: -1,
					size: size,
				})
			);
		} else {
			dispatch(removeItem({ product: { ...cartItemObject }, size: size }));
		}
	};

	const increment = () => {
		dispatch(
			modifyItemObject({
				product: { ...cartItemObject },
				quantity: 1,
				size: size,
			})
		);
	};

	return (
		<styled.CartItemCardWrapper>
			<styled.Image src={img} alt={title} />
			<styled.InfoWrapper>
				<styled.Title>{title}</styled.Title>
				<styled.Size size={size}>{size}</styled.Size>
				<styled.Description>{desc.slice(0, 10)}...</styled.Description>
			</styled.InfoWrapper>
			<styled.PriceInfoWrapper>
				<styled.QuantityPickerWrapper>
					<styled.IconWrapper className='Quantity' onClick={decrement}>
						<LiaChevronLeftSolid />
					</styled.IconWrapper>
					<styled.Quantity>{quantity}</styled.Quantity>
					<styled.IconWrapper className='Quantity' onClick={increment}>
						<LiaChevronRightSolid />
					</styled.IconWrapper>
				</styled.QuantityPickerWrapper>

				<styled.Price>${formatTotalItemPrice(price, quantity)}</styled.Price>
			</styled.PriceInfoWrapper>
		</styled.CartItemCardWrapper>
	);
};
export default CartItemCard;
