import { useEffect, useState } from 'react';
import * as styled from './styles.js';
import { IoMdStarOutline, IoMdStar, IoMdStarHalf } from 'react-icons/io';
import { TbCircleChevronDown } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import ReviewModal from '../ReviewModal/ReviewModal.jsx';

const ProductReview = ({ productId }) => {
	const [productReviews, setProductReviews] = useState([]);
	const [reviewCount, setReviewCount] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isReviewsOpen, setIsReviewsOpen] = useState(false);
	const [avgStars, setAvgStars] = useState(0);
	const allReviews = useSelector(
		(state) => state.reviews.allReviews.objectArray
	);

	useEffect(() => {
		const filteredReviews = allReviews.filter(
			(review) => productId === review.productId
		);

		if (filteredReviews.length > 0) {
			setReviewCount(filteredReviews.length);
			setAvgStars(avgStarCount(filteredReviews));
			setProductReviews([...filteredReviews]);
		} else {
			setReviewCount(0);
			setAvgStars(0);
			setProductReviews([]);
		}
	}, [allReviews, productId]);

	const avgStarCount = (productReviews) => {
		let totalStars = 0;
		productReviews.forEach((review) => {
			totalStars += review.starCount;
		});

		return productReviews.length > 0 ? totalStars / productReviews.length : 0;
	};

	const starFactory = (stars) => {
		const fullStars = Math.floor(stars);
		const halfStar = stars - fullStars >= 0.5;
		const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

		let icons = [];

		for (let i = 0; i < fullStars; i++) {
			icons.push(<IoMdStar key={`full-${i}`} />);
		}

		if (halfStar) {
			icons.push(<IoMdStarHalf key='half' />);
		}

		for (let i = 0; i < emptyStars; i++) {
			icons.push(<IoMdStarOutline key={`empty-${i}`} />);
		}

		return icons;
	};

	return (
		<styled.ProductReviewWrapper>
			<styled.AvgCountWrapper>
				<styled.ProductReviewAvgStar>
					{starFactory(avgStars)}
				</styled.ProductReviewAvgStar>
				<styled.ReviewCount>{reviewCount}</styled.ReviewCount>
				<styled.DropDownIcon
					aria-label='Reviews toggle button'
					role='button'
					aria-expanded={isReviewsOpen}
					onClick={() => setIsReviewsOpen(!isReviewsOpen)}
				>
					Reviews
					<TbCircleChevronDown
						style={
							isReviewsOpen
								? {
										transform: 'rotate(180deg)',
										marginLeft: '5px',
										transition: 'all .25s ease-out',
								  }
								: {
										marginLeft: '5px',
										transition: 'all .25s ease-out',
								  }
						}
					/>
				</styled.DropDownIcon>
			</styled.AvgCountWrapper>
			<styled.ReviewDropDownWrapper isopen={isReviewsOpen}>
				<styled.WriteAReview onClick={() => setIsModalOpen(true)}>
					Write A Review
				</styled.WriteAReview>
				<ReviewModal
					productId={productId}
					isOpen={isModalOpen}
					onRequestClose={() => setIsModalOpen(false)}
					setIsModalOpen={setIsModalOpen}
				/>
				{productReviews.length === 0 ? (
					<styled.NoReviews>No Reviews</styled.NoReviews>
				) : (
					<>
						{productReviews.map((review) => {
							return (
								<div key={review._id}>
									<styled.UserName>
										{DOMPurify.sanitize(review.name)}
									</styled.UserName>
									<styled.Date>
										{new Date(review.createdAt).toLocaleDateString()}
									</styled.Date>
									<styled.Stars>{starFactory(review.starCount)}</styled.Stars>
									<styled.ReviewTitle>
										{DOMPurify.sanitize(review.title.toUpperCase())}
									</styled.ReviewTitle>
									<styled.ReviewStatement>
										{DOMPurify.sanitize(review.reviewStatement)}
									</styled.ReviewStatement>
								</div>
							);
						})}
					</>
				)}
			</styled.ReviewDropDownWrapper>
		</styled.ProductReviewWrapper>
	);
};
export default ProductReview;
