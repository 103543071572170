import * as styled from './styles.js';
import { Link } from 'react-router-dom';

const MobileNavBar = ({ isNavMenuOpen, isMobileView, setIsNavMenuOpen }) => {
	return (
		<styled.MobileNav
			isMobileView={isMobileView}
			isNavMenuOpen={isNavMenuOpen}
			aria-label='Mobile Navigation Bar'
		>
			<styled.MobileNavMenu>
				<Link to={'/'} onClick={() => setIsNavMenuOpen(false)}>
					<styled.MobileNavItem>Home</styled.MobileNavItem>
				</Link>
				<Link to={'/about'} onClick={() => setIsNavMenuOpen(false)}>
					<styled.MobileNavItem>About</styled.MobileNavItem>
				</Link>
				<Link to={'/shop'} onClick={() => setIsNavMenuOpen(false)}>
					<styled.MobileNavItem>Shop</styled.MobileNavItem>
				</Link>
			</styled.MobileNavMenu>
		</styled.MobileNav>
	);
};
export default MobileNavBar;
