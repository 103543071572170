import { styled } from 'styled-components';

export const CartWrapper = styled.div`
	height: 100svh;
	width: clamp(100px, 80%, 300px);

	display: flex;
	flex-direction: column;
	align-items: center;

	position: fixed;
	right: -100%;
	top: 0;
	z-index: 999999;

	overflow-x: hidden;

	transition: all 0.35s ease-in;

	background-color: ${(props) => props.theme.colors.white};

	& button {
		border: 1px solid ${(props) => props.theme.colors.blackOpaque};
		box-shadow: 0 0 5px 0 ${(props) => props.theme.colors.sgrey},
			0 0 3px 1px ${(props) => props.theme.colors.sgreyOpaque};

		transition: all 0.25s ease-out;

		color: ${(props) => props.theme.colors.black};

		&:hover {
			transform: scale(1.1);
			box-shadow: 0 0 5px 1px ${(props) => props.theme.colors.sgrey},
				0 0 3px 1px ${(props) => props.theme.colors.sgreyOpaque},
				0 0 7px 1px ${(props) => props.theme.colors.sgreyOpaque};
		}
	}
`;

export const CartHeaderWrapper = styled.div`
	height: 10%;
	width: 100%;
	margin: 5px 15px;

	display: flex;
	align-items: center;
	justify-content: space-around;

	border-bottom: 1px solid ${(props) => props.theme.colors.black};
`;

export const ToggleButton = styled.button`
	display: flex;
	align-items: center;

	padding: 5px;

	border-radius: 15px;

	background-color: ${(props) => props.theme.colors.sgrey};
`;

export const CartTitle = styled.h2``;

export const CartItemsWrapper = styled.div`
	height: 80%;
	width: 100%;
	margin: 0 5px;

	display: flex;
	flex-direction: column;

	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${(props) => props.theme.colors.credOpaque};
		border-radius: 6px;
		border: 2px solid transparent;

		background-clip: content-box;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(50, 50, 50, 0.7);
	}

	&::-webkit-scrollbar-thumb:active {
		background-color: rgba(0, 0, 0, 0.9);
	}

	scrollbar-width: thin;
	scrollbar-color: ${(props) => props.theme.colors.credOpaque};
`;

export const CartFooterWrapper = styled.div`
	height: 10%;
	width: 100%;
	margin: 5px 15px;

	display: flex;
	align-items: center;
	justify-content: space-around;

	border-top: 1px solid ${(props) => props.theme.colors.black};
`;

export const CartTotalWrapper = styled.div`
	width: clamp(40px, 40%, 220px);

	display: flex;
	flex-direction: column;
`;

export const ClearCartButton = styled.button`
	width: clamp(40px, 40%, 220px);
	padding: 5px;

	border-radius: 5px;

	background-color: ${(props) => props.theme.colors.chred};
`;

export const CartTotalTitle = styled.h3`
	text-align: center;
`;

export const CartTotal = styled.span`
	text-align: center;
	margin-left: 30%;
`;

export const CartButtonWrapper = styled.div`
	height: 100%;
	width: 100%;

	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const CheckOutButton = styled.button`
	width: clamp(40px, 40%, 220px);
	padding: 5px;

	border-radius: 5px;

	color: ${(props) => props.theme.colors.white} !important;

	background-color: ${(props) => props.theme.colors.rblue};
`;
