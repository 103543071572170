import CarouselItem from './CarouselItem/CarouselItem';
import * as styled from './styles.js';
import Slider from 'react-slick';
import Contact from '../../Assets/img/contact.jpg';
import New from '../../Assets/img/new.jpg';
import Seasonal from '../../Assets/img/seasonal.jpg';
import Trending from '../../Assets/img/trending.jpg';
import { fadeInVariants } from '../../Assets/animations/animations';

const LandingCarouselData = [
	{
		id: 1,
		img: New,
		callToAction: 'The Freshest KC Style',
		buttonText: 'Shop The Newest Styles',
		buttonLink: '/shop',
	},
	{
		id: 2,
		img: Seasonal,
		callToAction: 'The Layered Look',
		buttonText: 'Shop The Seasonal Styles',
		buttonLink: '/shop',
	},
	{
		id: 3,
		img: Trending,
		callToAction: 'The Certified KC Look',
		buttonText: 'Shop The Trending Styles',
		buttonLink: '/shop',
	},
	{
		id: 4,
		img: Contact,
		callToAction: 'Stay Connected',
		buttonText: 'Contact Us',
		buttonLink: '/contact',
	},
];

const LandingCarousel = () => {
	const settings = {
		arrows: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		infinite: true,
		autoplaySpeed: 5000,
		speed: 100,
		cssEase: 'linear',
		pauseOnHover: true,
		swipeToSlide: true,
	};

	return (
		<styled.CarouselWrapper
			initial='hidden'
			animate='visible'
			variants={fadeInVariants}
		>
			<Slider {...settings}>
				{LandingCarouselData.map((carouselItem) => {
					return (
						<CarouselItem
							key={carouselItem.id}
							img={carouselItem.img}
							callToAction={carouselItem.callToAction}
							buttonText={carouselItem.buttonText}
							buttonLink={carouselItem.buttonLink}
						/>
					);
				})}
			</Slider>
		</styled.CarouselWrapper>
	);
};
export default LandingCarousel;
