import { ThemeProvider } from 'styled-components';

const theme = {
	colors: {
		//Chiefs
		chred: '#E31837',
		chredOpaque: '#e318367c',
		chgold: '#FFB81C',
		//Royals
		rblue: '#174885',
		rblue2: '#7bb2dd',
		rgold: '#c0995a',
		//Sporting KC
		sblue: '#002F65',
		sblue2: '#91B0D5',
		sgrey: '#999999',
		sgreyOpaque: '#999999ba',
		//Current
		cblue: '#62cbc9',
		cblue2: '#081f2c',
		cred: '#cf3339',
		credOpaque: '#cf333881',

		black: '#0f0f0f',
		offBlack: '#1b1b1b',
		blackOpaque: '#131212a6',
		white: '#fbfbfd',
		offWhite: '#fcfcf2',
		whiteOpaque: '#fbfbfdb5',
		grey: '#5f5f5f',
		greyOpaque: '#a8a8acc8',
		danger: '#FF0000',
		dangerOpaque: '#ff0000a4',
		success: '#00FF00',
		successOpaque: '#00ff00a4',
		caution: '#ffd900',
		cautionOpaque: '#ffd900a4',

		brandColorMain: '#8C0A0A',
		brandColorMainOpaque: '#8c0a0ab9',
		brandColorMainLight: '#9f1414',
		brandColorMainDark: '#790202',
		brandColorAccent: '#100E73',
		brandColorAccentDark: '#080760',
		border: '#8C7AA9',
		darkBackground: '#4b1621',
	},
};

const Theme = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
