import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isOpen: false,
	modalType: null,
	dataObject: null,
};

//modalTypes: success, fail, cancel, and error

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openModal: (state, action) => {
			state.isOpen = true;
			state.modalType = action.payload.modalType;
			state.dataObject = action.payload.dataObject;
		},
		closeModal: (state) => {
			state.isOpen = false;
			state.modalType = null;
			state.dataObject = null;
		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
