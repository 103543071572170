import styled from 'styled-components';

export const AboutWrapper = styled.main`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: transparent;
`;

export const AboutLanding = styled.section`
	height: 70svh;
	width: 100%;
	/* max-width: 1250px; */

	display: flex;
	justify-content: center;

	@media (max-width: 768px) {
		height: auto;
	}
`;

export const AboutLandingHeroImg = styled.img`
	height: 100%;
	width: 100%;
	/* max-width: 1100px; */

	object-fit: cover;
	object-position: bottom;
`;

export const MissionSatementWrapper = styled.article`
	width: 80%;
	max-width: 1100px;
	margin: 15px;

	display: flex;
	align-items: center;
	flex-direction: column;

	text-align: center;

	@media (max-width: 768px) {
		width: 95%;
		text-align: left;
	}
`;

export const MissionStatement = styled.p`
	font-size: 1.5rem;
	line-height: 2.5rem;

	max-width: 750px;
	margin-bottom: 15px;
	border-radius: 15px;

	background-color: ${(props) => props.theme.colors.greyOpaque};

	@media (max-width: 768px) {
		font-size: 1rem;
	}
`;

export const AboutSectionTitle = styled.h1`
	font-size: 3rem;
	margin-bottom: 15px;
`;

export const StaffWrapper = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`;

export const FeaturedWrapper = styled.div`
	width: 100%;
`;
