import { styled } from 'styled-components';
import { motion } from 'framer-motion';

export const CarouselItem = styled.div`
	height: 100%;
	width: 100%;

	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		content: '';
		height: 100%;
		width: 100%;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: ${(props) => props.theme.colors.blackOpaque};
	}
`;

export const Image = styled.img`
	max-width: 1100px;
	width: 100%;
	height: 100%;
	max-height: 70vh;

	object-fit: cover;
	object-position: center;
`;

export const CallToActionWrapper = styled.div`
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const CallToAction = styled(motion.span)`
	padding: 5px 10px;
	margin-bottom: 15px;

	text-align: center;

	font-size: 1.2rem;
	color: ${(props) => props.theme.colors.white};

	background-color: ${(props) => props.theme.colors.brandColorMainOpaque};
`;

export const CTAButton = styled(motion.button)`
	padding: 5px 10px;

	border-radius: 15px;
	border: solid 1px ${(props) => props.theme.colors.brandColorMain};

	background-color: ${(props) => props.theme.colors.brandColorMainOpaque};

	transition: transform 0.75s ease;

	&:hover {
		transform: scale(1.1);
		border: solid 2px ${(props) => props.theme.colors.white};
	}
`;
