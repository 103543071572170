import { styled } from 'styled-components';

export const MobileNav = styled.nav`
	position: absolute;
	top: 100%;
	right: -15px;
	padding: 0 15px;
	height: 150px;

	opacity: ${(props) => (props.isNavMenuOpen ? '1' : '0')};
	transform: ${(props) =>
		props.isNavMenuOpen ? 'translateY(0)' : 'translateY(-200%)'};
	background-color: ${(props) => props.theme.colors.brandColorMain};

	transition: all 0.35s ease-out;

	z-index: 0;

	display: ${(props) => (props.isMobileView ? 'block' : 'none')};
`;

export const MobileNavMenu = styled.ul`
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
`;

export const MobileNavItem = styled.li`
	font-size: 1.25rem;
	font-weight: 600;
	color: ${(props) => props.theme.colors.white};
	margin-top: 5px;
`;

export const MobileCartButton = styled.div`
	min-height: 15px;
	min-width: 15px;

	cursor: pointer;

	position: relative;
`;

export const MobileCartCountIcon = styled.span`
	position: absolute;
	left: 0;
	top: -5px;

	width: 1.2rem;
	height: 1.2rem;

	font-size: 0.75rem;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	border: 1.5px solid ${(props) => props.theme.colors.white};

	background-color: ${(props) => props.theme.colors.brandColorMainOpaque};
	color: ${(props) => props.theme.colors.white};
`;
