import { useState, useEffect } from 'react';

export const useHalfVisible = (elementRef) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const currentElement = elementRef.current;

		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{
				threshold: 0.4,
			}
		);

		if (currentElement) {
			observer.observe(currentElement);
		}

		return () => {
			observer.unobserve(currentElement);
		};
	}, [elementRef]);

	return isVisible;
};

export const useWholeVisible = (elementRef) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const currentElement = elementRef.current;

		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{
				threshold: 1,
			}
		);

		if (currentElement) {
			observer.observe(currentElement);
		}

		return () => {
			observer.unobserve(currentElement);
		};
	}, [elementRef]);

	return isVisible;
};
