import { Link } from 'react-router-dom';
import AddToCartButton from '../../Cart/AddToCartButton/AddToCartButton';
import * as styled from './styles.js';
import { formatPrice } from '../../../Assets/Helpers/formatPrice';
import { useRef, useState } from 'react';
import { useHalfVisible } from '../../../Assets/Helpers/useVisible';
import { fadeInVariants } from '../../../Assets/animations/animations';

const FeaturedCard = ({ id, slug, price, name, desc, img01, img02, size }) => {
	const cardRef = useRef();
	const isVisible = useHalfVisible(cardRef);
	const [selectedSize, setSelectedSize] = useState('M');

	const headerLinkStyles = {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	};
	const imgLinkStyles = {
		display: 'flex',
		justifyContent: 'center',
	};

	const handleSizeChange = (e) => {
		setSelectedSize(e.target.value);
	};

	return (
		<styled.FeaturedCardWrapper
			ref={cardRef}
			initial='hidden'
			animate={isVisible ? 'visible' : 'hidden'}
			variants={fadeInVariants}
		>
			<Link to={`/shop/${slug}`} style={headerLinkStyles}>
				<styled.FeaturedCardHeader>
					<styled.FeaturedTitle>{name}</styled.FeaturedTitle>
					<styled.Price>${formatPrice(price)}</styled.Price>
				</styled.FeaturedCardHeader>
			</Link>
			<Link to={`/shop/${slug}`} style={imgLinkStyles}>
				<styled.FeaturedImage
					size={size}
					src={img01}
					alt={name}
					hoverImg={img02}
				/>
			</Link>
			{size ? (
				<styled.SizeOptions>
					<label>Select Size:</label>
					<select onChange={handleSizeChange} value={selectedSize}>
						<option value='S'>S - Small</option>
						<option value='M'>M - Medium</option>
						<option value='L'>L - Large</option>
						<option value='XL'>XL - Extra Large</option>
						<option value='XXL'>XXL - Extra Extra Large</option>
					</select>
				</styled.SizeOptions>
			) : null}
			<styled.FeaturedCardButtonWrapper>
				<styled.Button>
					<AddToCartButton
						productId={id}
						quantity={1}
						productType={'featured'}
						size={selectedSize}
					/>
				</styled.Button>
			</styled.FeaturedCardButtonWrapper>
		</styled.FeaturedCardWrapper>
	);
};
export default FeaturedCard;
