import * as styled from './styles.js';
import { Link } from 'react-router-dom';
import { RiMenu3Fill } from 'react-icons/ri';
import { RiCloseFill } from 'react-icons/ri';
import useMobileBreakPoint from '../../Assets/Helpers/useMobileBreakPoint.jsx';
import { useState } from 'react';
import MobileNavBar from './MobileNavBar/MobileNavBar.jsx';
import NavBar from './NavBar/NavBar.jsx';
import Logo from '../../Assets/img/Logo.PNG';
import { BsBagFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { openCart } from '../../Assets/Slices/cartSlice.js';

const Header = () => {
	const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
	const dispatch = useDispatch();
	const itemsInCart = useSelector((state) => state.cart.itemCount);

	const handleNavMenuClick = () => {
		setIsNavMenuOpen(!isNavMenuOpen);
	};

	const handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			dispatch(openCart());
		}
	};

	const isMobileView = useMobileBreakPoint(768);

	return (
		<styled.Header>
			<styled.NavWrapper>
				<Link style={{ height: '100%' }} to={'/'}>
					<styled.Logo src={Logo} alt='Logo for Everything Unique' />
				</Link>
				{isMobileView ? (
					isNavMenuOpen ? (
						<styled.MobileNavIconWrapper isNavMenuOpen={isNavMenuOpen}>
							<RiCloseFill
								style={{ fontSize: '52px' }}
								role='button'
								onClick={() => handleNavMenuClick()}
							/>
						</styled.MobileNavIconWrapper>
					) : (
						<styled.MobileNavIconWrapper isNavMenuOpen={isNavMenuOpen}>
							<RiMenu3Fill
								style={{ fontSize: '48px' }}
								role='button'
								onClick={() => handleNavMenuClick()}
							/>
						</styled.MobileNavIconWrapper>
					)
				) : (
					<NavBar />
				)}
				<MobileNavBar
					setIsNavMenuOpen={setIsNavMenuOpen}
					isMobileView={isMobileView}
					isNavMenuOpen={isNavMenuOpen}
				/>
			</styled.NavWrapper>
			<styled.CartButton className='CartButton'>
				<styled.CartCountIcon>{itemsInCart}</styled.CartCountIcon>
				<BsBagFill
					role='button'
					tabIndex={0}
					style={{ fontSize: '1.25rem' }}
					onClick={() => dispatch(openCart())}
					onKeyDown={handleEnterKey}
				/>
			</styled.CartButton>
		</styled.Header>
	);
};
export default Header;
