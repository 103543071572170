import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	queryString: '',
	filterPriceRangeNum: 100,
	filterOrganization: undefined,
	filterOptionsArray: [],
	resultsObjectArray: [],
	openBoolean: false,
};

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		searchQuery: (state, action) => {
			state.queryString = action.payload;
		},
		filterPriceRange: (state, action) => {
			state.filterPriceRangeNum = action.payload;
		},
		filterPriceOrganization: (state, action) => {
			state.filterOrganization = action.payload;
		},
		filterQuery: (state, action) => {
			const duplicate = state.filterOptionsArray.includes(action.payload);

			if (duplicate) {
				state.filterOptionsArray = state.filterOptionsArray.filter(
					(option) => option !== action.payload
				);
			} else {
				state.filterOptionsArray = [
					...state.filterOptionsArray,
					action.payload,
				];
			}
		},
		clearFilters: (state) => {
			state.filterOptionsArray = [];
			state.filterPriceRangeNum = 100;
			state.filterOrganization = undefined;
		},
		openFilter: (state) => {
			state.openBoolean = true;
		},
		closeFilter: (state) => {
			state.openBoolean = false;
		},
	},
});

export const {
	searchQuery,
	filterPriceRange,
	filterPriceOrganization,
	filterQuery,
	clearFilters,
	openFilter,
	closeFilter,
} = searchSlice.actions;

export default searchSlice.reducer;
