import { useDispatch, useSelector } from 'react-redux';
import * as styled from './styles.js';
import { addItem } from '../../../Assets/Slices/cartSlice.js';
import { BsFillBagPlusFill } from 'react-icons/bs';

const AddToCartButton = ({ productId, quantity, productType, size }) => {
	const allProducts = useSelector(
		(state) => state.products.allProducts.objectArray
	);
	const dispatch = useDispatch();

	const handleATCClick = () => {
		const product = allProducts.find((product) => product.id === productId);

		const productQuantityAndSize = {
			product: { ...product },
			quantity: quantity,
			size: size,
		};
		dispatch(addItem(productQuantityAndSize));
	};

	return (
		// productType isn't really being used right now but could be implemented for sales tracking
		<styled.AddToCartButton productType={productType} onClick={handleATCClick}>
			Add to Cart
			<BsFillBagPlusFill
				style={{
					marginLeft: '1rem',
					marginBottom: '5px',
					fontSize: '1.1rem',
				}}
			/>
		</styled.AddToCartButton>
	);
};
export default AddToCartButton;
