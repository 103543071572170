import { styled } from 'styled-components';

export const CartItemCardWrapper = styled.div`
	width: 100%;
	padding: 15px;
	margin-bottom: 5px;

	display: flex;
	align-items: center;
	justify-content: space-around;

	background-color: ${(props) => props.theme.colors.sgrey};
`;

export const Image = styled.img`
	height: 60px;
	width: 50px;

	object-fit: center;
`;

export const InfoWrapper = styled.div`
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`;

export const Title = styled.h3``;

export const Description = styled.p``;

export const PriceInfoWrapper = styled.div`
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`;

export const QuantityPickerWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const IconWrapper = styled.div`
	cursor: pointer;

	transition: all 0.15s ease-in;

	&:hover {
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.9);
	}
`;

export const Quantity = styled.p``;

export const Price = styled.p``;

export const Size = styled.p`
	display: ${(props) => (props.size ? 'block' : 'none')};
`;
