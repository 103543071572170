import './App.css';

import { Routes, Route } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { createGlobalStyle, styled } from 'styled-components';
import { useDispatch } from 'react-redux';

import { useGetAllProductsAndReviewsQuery } from './Assets/Slices/apiSlice.js';
import {
	sortAll,
	sortFeatured,
	sortSeasonal,
	sortTrending,
} from './Assets/Slices/productsSlice';
import { getReviews } from './Assets/Slices/reviewsSlice.js';
import CheckOutParamsHandler from './Assets/Helpers/CheckOutParamsHandler';

import Homepage from './Components/HomePage/Homepage';
import ShopPage from './Components/ShopPage/ShopPage';
import AboutPage from './Components/AboutPage/AboutPage';
import ProductPage from './Components/ProductPage/ProductPage';
import Banner from './Components/Banner/Banner';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
// import Socials from './Components/Socials/Socials';
import ShopList from './Components/ShopPage/ShopLIst/ShopList';
import Cart from './Components/Cart/Cart';
import Theme from './Components/Theme';
import ScrollToTop from './Assets/Helpers/ScrollToTop';
import SiteModal from './Components/Modal/Modal';
import Contact from './Components/Contact/Contact';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

const MasterWrapper = styled.div`
	min-height: 100svh;
	height: 100vh;

	position: relative;

	overflow-x: hidden;

	display: flex;
	flex-direction: column;
`;

function App() {
	const dispatch = useDispatch();
	const masterWrapperRef = useRef(null);
	let content;

	const { data, isLoading, isSuccess, isError, error } =
		useGetAllProductsAndReviewsQuery();

	useEffect(() => {
		if (isSuccess) {
			dispatch(sortAll(data));
			dispatch(sortFeatured(data));
			dispatch(sortSeasonal(data));
			dispatch(sortTrending(data));
			dispatch(getReviews(data));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, data]);

	//work on loading state.  Make it so its not hung up on just text.

	if (isLoading) {
		content = (
			<Routes>
				<Route path='/' element={<Homepage />} />
				<Route path='/about' element={<AboutPage />} />
				<Route path='/contact' element={<Contact />} />
			</Routes>
		);
	} else if (isError) {
		console.log(error);
	} else if (isSuccess) {
		content = (
			<Routes>
				<Route path='/' element={<Homepage />} />
				<Route path='/about' element={<AboutPage />} />
				<Route path='/shop' element={<ShopPage />}>
					<Route index element={<ShopList />} />
					<Route path=':slug' element={<ProductPage />} />
				</Route>
				<Route path='/checkout' />
				<Route path='/contact' element={<Contact />} />
			</Routes>
		);
	} else {
		content = <p style={{ height: '50vh' }}>Oh no. Something went wrong.</p>;
	}
	return (
		<Theme>
			<MasterWrapper className='App' ref={masterWrapperRef}>
				<GlobalStyle />
				<ScrollToTop containerRef={masterWrapperRef} />
				<CheckOutParamsHandler />
				<SiteModal />
				<Cart />
				<Banner />
				{/* <Socials /> */}
				<Header />
				{content}
				<Footer />
			</MasterWrapper>
		</Theme>
	);
}

export default App;
