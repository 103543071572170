import styled from 'styled-components';

export const HomeWrapper = styled.main`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: transparent;
`;

export const FeaturedWrapper = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: transparent;
`;

export const CallToAction = styled.section`
	width: 100%;
	min-height: 40vh;
	margin: 0 10px 10px 10px;

	border-radius: 50px;

	background-color: ${(props) => props.theme.colors.offBlack};

	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 660px) {
		margin-bottom: 25px;
	}
`;

export const ReviewWrapper = styled.section`
	width: 100%;
	height: 40svh;
	max-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: transparent;

	@media (max-width: 660px) {
		margin-bottom: 25px;
	}
`;

export const TrendingWrapper = styled.section`
	width: 100%;
`;
