import { useEffect, useState } from 'react';
// import FilteredSearch from '../../FilteredSearch/FilteredSearch';
import ShopCard from '../ShopCard/ShopCard';
import * as styled from './styles.js';
import { useSelector } from 'react-redux';
// import MobileFilteredSearch from '../../FilteredSearch/MobileFilteredSearch/MobileFilteredSearch';
// import useMobileBreakPoint from '../../../Assets/Helpers/useMobileBreakPoint.jsx';

//Taking out filteredSearch cause there is only 4 products that are all shirts with the same price and design

const ShopList = () => {
	// const isMobileView = useMobileBreakPoint(768);

	const allProducts = useSelector(
		(state) => state.products.allProducts.objectArray
	);
	const filters = useSelector((state) => state.search.filterOptionsArray);
	const maxPrice = useSelector((state) => state.search.filterPriceRangeNum);
	const priceOrg = useSelector((state) => state.search.filterOrganization);

	const [filteredProducts, setFilteredProducts] = useState(allProducts);

	useEffect(() => {
		let newFilteredProducts = [...allProducts];

		if (filters.length === 0 && priceOrg === undefined) {
			newFilteredProducts = allProducts;
		}

		if (filters.length > 0) {
			newFilteredProducts = newFilteredProducts.filter((product) =>
				filters.includes(product.metadata.category)
			);
		}

		if (maxPrice <= 100) {
			newFilteredProducts = newFilteredProducts.filter(
				(product) => product.price <= maxPrice
			);
		}

		if (priceOrg === 'lowestFirst') {
			newFilteredProducts.sort((a, b) => a.price - b.price);
		} else if (priceOrg === 'highestFirst') {
			newFilteredProducts.sort((a, b) => b.price - a.price);
		}

		setFilteredProducts(newFilteredProducts);
	}, [allProducts, filters, maxPrice, priceOrg]);

	return (
		<styled.ShopWrapper>
			{/* <styled.FilterWrapper>
				{isMobileView ? <MobileFilteredSearch /> : <FilteredSearch />}
			</styled.FilterWrapper> */}
			<styled.ShopSection>
				{filteredProducts.map((product) => {
					return (
						<ShopCard
							key={product.id}
							id={product.id}
							desc={product.description}
							img01={product.images[0]}
							img02={product.images[1]}
							price={product.price}
							name={product.name}
							slug={product.slug}
							size={product.metadata.sizes}
						/>
					);
				})}
			</styled.ShopSection>
		</styled.ShopWrapper>
	);
};
export default ShopList;
