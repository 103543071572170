import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '../Slices/modalSlice';
import { clearCart, closeCart } from '../Slices/cartSlice';

const CheckOutParamsHandler = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const isSuccess = searchParams.get('success');
		const isCancel = searchParams.get('canceled');

		//modalTypes: success, fail, cancel, and error

		if (isSuccess) {
			dispatch(
				openModal({
					modalType: 'success',
					dataObject: {
						modalTitle: 'Your Purchase Was Successful',
						modalText: 'Congratulations!!!',
					},
				})
			);
			dispatch(clearCart());
			dispatch(closeCart());
		} else if (isCancel) {
			dispatch(
				openModal({
					modalType: 'cancel',
					dataObject: {
						modalTitle: 'Your Purchase Was Canceled',
						modalText: "Keep shopping and checkout when you're ready!",
					},
				})
			);
		} else {
			dispatch(closeModal());
		}
	}, [location, dispatch]);
};
export default CheckOutParamsHandler;
