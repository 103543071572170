import styled from 'styled-components';

export const Footer = styled.footer`
	width: 100%;

	padding: 15px 10%;

	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 10;

	background-color: ${(props) => props.theme.colors.offBlack};

	@media (max-width: 660px) {
		flex-direction: column;
	}
`;

export const FooterCTANav = styled.nav`
	width: clamp(80%, 50%, 1100px);

	margin-bottom: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const FooterCTAList = styled.ul`
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: space-around;

	width: 100%;

	margin: 15px;
`;

export const FooterCTAListItem = styled.li`
	display: flex;
	align-items: center;

	margin-bottom: 15px;
`;

export const FooterCTAText = styled.p`
	color: ${(props) => props.theme.colors.offWhite};
	font-size: 0.65rem;

	margin-right: 10px;
`;

export const NavItem = styled.p`
	outline: 1px solid ${(props) => props.theme.colors.offWhite};
	outline-offset: 2px;

	color: ${(props) => props.theme.colors.offWhite};
	font-size: 0.65rem;
	text-align: center;

	padding: 2px;

	transition: all 0.25s ease-in-out;

	&:hover {
		background-color: ${(props) => props.theme.colors.offWhite};

		outline: 1px solid ${(props) => props.theme.colors.offBlack};
		outline-offset: -2px;

		color: ${(props) => props.theme.colors.offBlack};

		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.9);
	}
`;

export const CopyRight = styled.p`
	line-break: none;

	font-size: 0.65rem;
	color: ${(props) => props.theme.colors.offWhite};
`;
