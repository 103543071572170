import styled from 'styled-components';

export const ContactFormWrapper = styled.section`
	display: flex;
	flex-direction: ${(props) => (props.isMobileView ? 'column' : 'row')};
	flex: 1;
	justify-content: center;

	width: 100vw;

	background: linear-gradient(
		to bottom,
		#d9d5d3 70%,
		#e3dfda 80%,
		#fbf6f3 90%,
		#f3f2fa 100%
	);
`;

export const ContactImage = styled.img`
	width: 50%;
	object-fit: contain;
`;

export const ContactForm = styled.form`
	width: ${(props) => (props.isMobileView ? '100%' : '50%')};
	height: ${(props) => (props.isMobileView ? '75vh' : 'auto')};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	border-left: 2px solid ${(props) => props.theme.colors.chred};
`;

export const FormTitle = styled.h2``;

export const FormLabel = styled.label`
	transform: translateX(-9999999px);
	height: 0px;
	width: 0px;
`;

export const FormInput = styled.input`
	all: unset;

	width: 80%;
	padding: 5px 0 5px 15px;

	border: 1px solid ${(props) => props.theme.colors.chred};
	border-radius: 15px;

	&:focus {
		caret-color: ${(props) => props.theme.colors.chred};
	}
`;

export const FormTextArea = styled.textarea`
	all: unset;

	width: 80%;
	height: 40%;
	padding: 15px 0 5px 15px;

	border: 1px solid ${(props) => props.theme.colors.chred};
	border-radius: 15px;

	&:focus {
		caret-color: ${(props) => props.theme.colors.chred};
	}
`;

export const SubmitButton = styled.button`
	padding: 5px 15px;

	border: 1px solid ${(props) => props.theme.colors.chred};
	border-radius: 15px;

	transition: all 0.35s ease-out;

	&:hover {
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.9);
	}
`;
