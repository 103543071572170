export const fadeInVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 1 } },
};

export const fadeInVariantsDelay100 = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { delay: 1, duration: 1 } },
};

export const fadeInVariantsDelay125 = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { delay: 1.25, duration: 1 } },
};

export const slideInFromLeft = {
	hidden: { x: '-180%' },
	visible: { x: 0 },
};

export const slideInFromRight = {
	hidden: { x: '180%' },
	visible: { x: 0 },
};
