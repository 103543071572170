import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		// production 'https://everything-kansas-city.onrender.com'
		// development 'http://localhost:5000'
		baseUrl: 'https://everything-kansas-city.onrender.com',
	}),
	tagTypes: ['Reviews', 'Products'],
	endpoints: (builder) => ({
		//decouple products and review.  everytime someone rights a review the entire website reloads and that's bad for money!!!!!
		getAllProductsAndReviews: builder.query({
			query: () => '/',
			providesTags: ['Reviews', 'Products'],
			// needs to be decoupled but its the only way its working currently
		}),
		//this isnt invalidating 'Reviews' properly
		// getLatestReviews: builder.query({
		// 	query: () => '/reviews/latest',
		// 	method: 'GET',
		// 	providesTags: ['Reviews'],
		// }),
		createNewReview: builder.mutation({
			query: (newReview) => ({
				url: '/reviews/new',
				method: 'POST',
				body: newReview,
			}),
			invalidatesTags: ['Reviews'],
		}),
		customerCheckout: builder.mutation({
			query: (products) => ({
				url: '/checkout/create-checkout-session',
				method: 'POST',
				body: products,
			}),
		}),
	}),
});

export const {
	// useGetLatestReviewsQuery,
	useGetAllProductsAndReviewsQuery,
	useCreateNewReviewMutation,
	useCustomerCheckoutMutation,
} = apiSlice;
