import styled from 'styled-components';

export const ShopWrapper = styled.div`
	min-height: 100vh;
	width: 100%;
	margin-bottom: 15px;

	display: flex;
	justify-content: center;
`;

export const FilterWrapper = styled.div`
	height: 100vh;

	background-color: ${(props) => props.theme.colors.white};

	position: sticky;
	top: 60px;
`;

export const ShopSection = styled.section`
	width: 80%;
	max-width: 1100px;

	display: grid;
	justify-content: center;
	grid-template-columns: repeat(auto-fill, minmax(150px, 250px));
	grid-auto-rows: 400px;

	gap: 20px;
	margin-top: 20px;

	@media (max-width: 880px) {
		justify-content: start;
	}

	@media (max-width: 768px) {
		width: 100%;
		margin-top: 75px;

		align-items: center;
		justify-content: center;
	}
`;
