import { useSelector } from 'react-redux';
import ReviewCard from './ReviewCard/ReviewCard.jsx';
import * as styled from './styles.js';
import Slider from 'react-slick';

const Reviews = () => {
	const allProducts = useSelector(
		(state) => state.products.allProducts.objectArray
	);
	const reviews = useSelector((state) => {
		if (state.reviews.allReviews.isSorted === true) {
			let goodReviewsArray = state.reviews.allReviews.objectArray
				.filter((review) => review.starCount > 3 && review.productId !== null)
				.sort(() => Math.random() - 0.5)
				.slice(0, 4);

			return goodReviewsArray;
		}

		return [];
	});

	const settings = {
		arrows: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		infinite: true,
		autoplaySpeed: 5000,
		speed: 100,
		cssEase: 'linear',
		pauseOnHover: true,
		swipeToSlide: true,
	};
	return (
		<styled.ReviewWrapper>
			<Slider {...settings}>
				{reviews?.map((review) => {
					const product = allProducts.filter(
						(product) => review.productId === product.id
					);

					return (
						<ReviewCard
							key={review._id}
							name={review.name}
							date={review.createdAt}
							title={review.title}
							review={review.reviewStatement}
							stars={review.starCount}
							slug={product[0]?.slug}
						/>
					);
				})}
			</Slider>
		</styled.ReviewWrapper>
	);
};
export default Reviews;
