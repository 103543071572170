import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { LiaChevronLeftSolid, LiaChevronRightSolid } from 'react-icons/lia';

import * as styled from './styles.js';
// import Trending from '../Trending/Trending.jsx';
import AddToCartButton from '../Cart/AddToCartButton/AddToCartButton.jsx';
import ProductReview from '../Reviews/ProductReview/ProductReview.jsx';
import { formatPrice } from '../../Assets/Helpers/formatPrice.js';

const ProductPage = () => {
	const { slug } = useParams();
	const [quantity, setQuantity] = useState(1);
	const [size, setSize] = useState('M');
	const sorted = useSelector((state) => state.products.allProducts.isSorted);
	const allProducts = useSelector(
		(state) => state.products.allProducts.objectArray
	);

	const product = allProducts.find((product) => product.slug === slug);
	// console.log('product from product view page:', product);

	const [mainImg, setMainImg] = useState(
		product?.images ? product.images[0] : null
	);

	useEffect(() => {
		if (product?.images) {
			setMainImg(product.images[0]);
		}
	}, [product]);

	if (!sorted) {
		return <p>Loading...</p>;
	}

	const { id, images, name, price, description, metadata } = product;

	const decrement = () => {
		if (quantity > 1) {
			setQuantity((quantity) => (quantity -= 1));
		}
	};

	const increment = () => {
		setQuantity((quantity) => (quantity += 1));
	};

	const handleSizeChange = (e) => {
		setSize(e.target.value);
	};

	return (
		<styled.ProductWrapper>
			<styled.ProductView>
				<styled.ProductDisplayImgWrapper>
					<img src={mainImg} alt='alt for img' />
					<styled.ImgNavWrapper>
						{images.map((img, index) => (
							<img
								key={index}
								src={img}
								alt={`Thumbnail ${img}`}
								onClick={() => setMainImg(img)}
								style={
									img === mainImg
										? {
												border: 'solid 1px #174885',

												transform: 'scale(1.1)',
										  }
										: {
												border: 'none',
												transform: 'scale(1)',
										  }
								}
							/>
						))}
					</styled.ImgNavWrapper>
				</styled.ProductDisplayImgWrapper>
				<styled.ProductInfoWrapper>
					<>
						<styled.ProductTitle>{name}</styled.ProductTitle>
						<styled.ProductDescription>{description}</styled.ProductDescription>
						<styled.ProductPrice>${formatPrice(price)}</styled.ProductPrice>
					</>
					<styled.ProductReviewComponentWrapper>
						<ProductReview productId={id} />
					</styled.ProductReviewComponentWrapper>
					{metadata.sizes ? (
						<styled.SizeOptions>
							<label htmlFor='size'>Select Size:</label>
							<select id='size' onChange={handleSizeChange} value={size}>
								<option value='S'>S - Small</option>
								<option value='M'>M - Medium</option>
								<option value='L'>L - Large</option>
								<option value='XL'>XL - Extra Large</option>
								<option value='XXL'>XXL - Extra Extra Large</option>
							</select>
						</styled.SizeOptions>
					) : null}
					<styled.IconWrapper>
						<styled.Icon>
							<LiaChevronLeftSolid onClick={decrement} />
						</styled.Icon>
						<styled.QuantityPickerWrapper>
							<label>Quantity:</label>
							<p> {quantity} </p>
						</styled.QuantityPickerWrapper>
						<styled.Icon>
							<LiaChevronRightSolid onClick={increment} />
						</styled.Icon>
					</styled.IconWrapper>
					<styled.ATCButtonWrapper>
						<AddToCartButton
							productId={id}
							quantity={quantity}
							size={size}
							productType={null}
						/>
					</styled.ATCButtonWrapper>
				</styled.ProductInfoWrapper>
			</styled.ProductView>
			{/* <Trending /> */}
		</styled.ProductWrapper>
	);
};

export default ProductPage;
