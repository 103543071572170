export const formatPrice = (productPrice) => {
	const productPriceString = productPrice.toString();

	if (!productPriceString.includes('.')) {
		return productPriceString + '.00';
	}

	const [wholePart, decimalPart] = productPriceString.split('.');

	if (decimalPart.length === 1) {
		return wholePart + decimalPart + '0';
	} else if (decimalPart.length > 2) {
		return wholePart + '.' + decimalPart.substring(0, 2);
	}

	return productPriceString;
};

export const formatTotalItemPrice = (price, quantity) => {
	const priceTimesQuantity = price * quantity;
	const priceString = priceTimesQuantity.toString();

	if (!priceString.includes('.')) {
		return priceString + '.00';
	}

	const [wholePart, decimalPart] = priceString.split('.');

	if (decimalPart.length === 1) {
		return wholePart + decimalPart + '0';
	} else if (decimalPart.length > 2) {
		return wholePart + '.' + decimalPart.substring(0, 2);
	}

	return priceString;
};
