import { Link } from 'react-router-dom';
import * as styled from './styles.js';
import {
	fadeInVariantsDelay100,
	fadeInVariantsDelay125,
} from '../../../Assets/animations/animations.js';

const CarouselItem = ({ img, callToAction, buttonText, buttonLink }) => {
	return (
		<styled.CarouselItem>
			<styled.Image src={img} alt={buttonText}></styled.Image>

			<styled.CallToActionWrapper>
				<styled.CallToAction
					initial='hidden'
					animate='visible'
					variants={fadeInVariantsDelay100}
				>
					{callToAction}
				</styled.CallToAction>

				<styled.CTAButton
					initial='hidden'
					animate='visible'
					variants={fadeInVariantsDelay125}
				>
					<Link
						style={{
							color: '#fbfbfd',
						}}
						to={buttonLink}
					>
						{buttonText}
					</Link>
				</styled.CTAButton>
			</styled.CallToActionWrapper>
		</styled.CarouselItem>
	);
};
export default CarouselItem;
