import styled from 'styled-components';

export const FeaturedWrapper = styled.div`
	width: 100%;
	margin-bottom: 85px;

	display: flex;
	flex-wrap: wrap;
	gap: 1px 5px;
	align-items: center;
	justify-content: center;
`;

export const FeaturedBannerWrapper = styled.p`
	width: 100%;
	padding: 5px 0;
	margin-left: 10%;

	display: flex;
	align-items: center;
`;

export const FeaturedBanner = styled.h3`
	color: ${(props) => props.theme.colors.brandColorAccentDark};
`;
