import styled from 'styled-components';

export const ShopWrapper = styled.main`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: transparent;
`;
