import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CarouselWrapper = styled(motion.section)`
	width: 100%;

	height: 70svh;

	margin-bottom: 25px;

	@media (max-width: 768px) {
		height: auto;
	}
`;
