import { keyframes, styled } from 'styled-components';

export const Header = styled.header`
	background-color: ${(props) => props.theme.colors.brandColorMain};
	color: ${(props) => props.theme.colors.white};

	min-height: 70px;
	width: 100%;

	position: sticky;
	top: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 10;
`;

export const NavWrapper = styled.div`
	width: 100%;
	max-width: 1200px;
	height: 100%;
	margin: 0 15px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;

	position: relative;
`;

export const Logo = styled.img`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 70px;
	width: 200px;
`;

export const MobileNavIconWrapper = styled.span`
	animation: ${(props) => (props.isNavMenuOpen ? rotate : 'none')} 0.1s linear,
		${(props) => (props.isNavMenuOpen ? 'none' : balloon)} 0.25s linear;

	position: relative;
	cursor: pointer;
`;

export const CartButton = styled.div`
	min-height: 15px;
	min-width: 15px;
	margin-right: 15px;

	cursor: pointer;

	position: relative;
`;

export const CartCountIcon = styled.span`
	position: absolute;
	left: -10px;
	top: -15px;

	width: 1.2rem;
	height: 1.2rem;

	font-size: 0.75rem;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	border: 1.5px solid ${(props) => props.theme.colors.white};

	background-color: ${(props) => props.theme.colors.chredOpaque};
	color: ${(props) => props.theme.colors.white};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const balloon = keyframes`
	0% {
		transform: scale(0.25);
	}
	25% {
		transform: scale(0);
	}
	50% {
		transform: scale(.75);
	}
	75% {
		transform: scale(1.25);
	}
	100% {
		transform: scale(1);
	}
`;
