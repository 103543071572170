import styled from 'styled-components';

export const AddToCartButton = styled.button`
	width: 100%;
	height: 100%;
	min-height: 40px;

	padding: 15px;

	font-size: 1rem;

	display: flex;
	justify-content: center;
	align-items: center;

	color: ${(props) => props.theme.colors.offWhite};

	background-color: ${(props) => {
		switch (props.productType) {
			case 'featured':
				return props.theme.colors.brandColorAccent;
			case 'trending':
				return props.theme.colors.brandColorMain;
			default:
				return props.theme.colors.grey;
		}
	}};

	border-radius: 15px;

	transition: all 0.35s ease-out;

	&:hover {
		transform: scale(1.05);
	}

	&:active {
		transform: scale(0.9);
	}
`;
