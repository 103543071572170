import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../Assets/Slices/modalSlice.js';
import ModalContent from './ModalContent/ModalContent.jsx';

const SiteModal = () => {
	const dispatch = useDispatch();
	const { isOpen, modalType, dataObject } = useSelector((state) => state.modal);

	const handleClose = () => {
		dispatch(closeModal());
	};

	const customStyles = {
		overlay: {
			zIndex: 9999999,
			background: 'none',
		},
		content: {
			top: '10%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			padding: 0,
			maxWidth: '800px',
		},
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={handleClose}
			shouldCloseOnOverlayClick={true}
			style={customStyles}
		>
			<ModalContent
				modalType={modalType}
				dataObject={dataObject}
				onClose={handleClose}
			/>
		</Modal>
	);
};

export default SiteModal;
