import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	allReviews: {
		isSorted: false,
		objectArray: [],
	},
};

export const reviewsSlice = createSlice({
	name: 'reviews',
	initialState,
	reducers: {
		getReviews: (state, action) => {
			if (action.payload.reviews) {
				state.allReviews.objectArray = [...action.payload.reviews];
				state.allReviews.isSorted = true;
			}
		},
	},
});

export const { getReviews } = reviewsSlice.actions;

export default reviewsSlice.reducer;
