import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FeaturedCardWrapper = styled(motion.article)`
	width: clamp(200px, 80%, 250px);

	margin: 15px;

	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: ${(props) => props.theme.colors.white};
`;

export const FeaturedCardHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	background-color: ${(props) => props.theme.colors.brandColorAccent};

	padding: 5px 10px;
	margin: 5px 0;
	width: 100%;
`;

export const FeaturedTitle = styled.h3`
	color: ${(props) => props.theme.colors.offWhite};
`;

export const Price = styled.h4`
	color: ${(props) => props.theme.colors.offWhite};

	text-align: end;
`;

export const FeaturedImage = styled.img`
	max-width: 230px;
	height: 300px;

	transition: all 1s ease-out;

	/* used this to make the cards the same size when they had/didn't-have size */
	/* margin: ${(props) => (props?.sizes ? '0' : '44px 0 37px 0')}; */

	&:hover {
		content: url(${(props) => props.hoverImg});
	}
`;

export const SizeOptionsWrapper = styled.div`
	width: 100%;
	border: 1px solid hotpink;
`;

export const SizeOptions = styled.form`
	margin: 15px 0 15px 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> select,
	select:active {
		border: none;
	}
`;

export const FeaturedCardButtonWrapper = styled.div`
	width: 100%;

	display: flex;
	justify-content: space-around;
	align-items: center;
`;
export const Button = styled(motion.div)`
	width: 100%;
`;
