import * as styled from './styles.js';
import FeaturedCard from './FeaturedCard/FeaturedCard.jsx';
import { useSelector } from 'react-redux';

const Featured = () => {
	const featuredProducts = useSelector(
		(state) => state.products.featuredProducts.objectArray
	);

	return (
		<styled.FeaturedWrapper>
			<styled.FeaturedBannerWrapper>
				<styled.FeaturedBanner>Featured Products</styled.FeaturedBanner>
			</styled.FeaturedBannerWrapper>
			{featuredProducts.slice(0, 3).map((product) => {
				return (
					<FeaturedCard
						key={product.id}
						id={product.id}
						slug={product.slug}
						price={product.price}
						name={product.name}
						desc={product.description}
						img01={product.images[0]}
						img02={product.images[1]}
						size={product.metadata.sizes}
					/>
				);
			})}
		</styled.FeaturedWrapper>
	);
};
export default Featured;
