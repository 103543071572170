import * as styled from './styles.js';
import { useSelector, useDispatch } from 'react-redux';
import { BsBagFill } from 'react-icons/bs';
import { TfiClose } from 'react-icons/tfi';
import CartItemCard from './CartItemCard/CartItemCard.jsx';
import { clearCart, closeCart } from '../../Assets/Slices/cartSlice.js';
import { formatPrice } from '../../Assets/Helpers/formatPrice.js';
import { useCustomerCheckoutMutation } from '../../Assets/Slices/apiSlice.js';
import { useEffect } from 'react';
import { motion } from 'framer-motion';

const Cart = () => {
	const cartOpen = useSelector((state) => state.cart.open);
	const cartTotal = useSelector((state) => state.cart.cartTotal);
	const cartItems = useSelector((state) => state.cart.itemObjectArray);
	const [customerCheckout, { isLoading }] = useCustomerCheckoutMutation();
	const dispatch = useDispatch();

	const handleCheckOutClick = async () => {
		try {
			const response = await customerCheckout([...cartItems]).unwrap();
			if (response?.url) {
				window.location.href = response.url;
			}
		} catch (error) {
			//add error handling ie modal
			console.log(
				'error with post request to /checkout/create-checkout-session:',
				error
			);
		}
	};

	useEffect(() => {
		const handleDocumentClick = (e) => {
			if (cartOpen) {
				if (
					e.target.closest('.CartWrapper') === null &&
					e.target.closest('.ToggleCartButton') === null &&
					e.target.closest('.CartButton') === null &&
					e.target.closest('.Quantity') === null
				) {
					dispatch(closeCart());
				}
			}
		};

		document.addEventListener('click', handleDocumentClick);

		return () => {
			document.removeEventListener('click', handleDocumentClick);
		};
	}, [cartOpen, dispatch]);

	return (
		<>
			{cartOpen ? (
				<styled.CartWrapper className='CartWrapper' style={{ right: '0' }}>
					<styled.CartHeaderWrapper>
						<styled.CartTitle>
							Cart <BsBagFill style={{ fontSize: '.9rem' }} />
						</styled.CartTitle>
						<styled.ToggleButton onClick={() => dispatch(closeCart())}>
							<span style={{ marginRight: '5px' }}>Close</span>
							<TfiClose />
						</styled.ToggleButton>
					</styled.CartHeaderWrapper>
					<styled.CartItemsWrapper>
						{cartItems.map((item, index) => {
							return (
								<motion.div
									key={item.id + item.size}
									initial={{ x: 500 }}
									animate={{ x: 0 }}
									transition={{
										delay: index * 0.35,
										duration: 0.5,
									}}
								>
									<CartItemCard
										key={item.id + item.size}
										id={item.id}
										img={item.images[0]}
										desc={item.description}
										title={item.name}
										price={item.price}
										quantity={item.quantity}
										size={item.size}
									/>
								</motion.div>
							);
						})}
					</styled.CartItemsWrapper>
					<styled.CartFooterWrapper>
						<styled.CartTotalWrapper>
							<styled.CartTotalTitle>Subtotal:</styled.CartTotalTitle>
							<styled.CartTotal>${formatPrice(cartTotal)}</styled.CartTotal>
						</styled.CartTotalWrapper>
						<styled.CartButtonWrapper>
							<styled.ClearCartButton onClick={() => dispatch(clearCart())}>
								Clear Cart
							</styled.ClearCartButton>
							<styled.CheckOutButton onClick={handleCheckOutClick}>
								Check Out
							</styled.CheckOutButton>
						</styled.CartButtonWrapper>
					</styled.CartFooterWrapper>
				</styled.CartWrapper>
			) : (
				<styled.CartWrapper></styled.CartWrapper>
			)}
		</>
	);
};
export default Cart;
