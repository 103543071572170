import { useState } from 'react';
import * as styled from './styles.js';
import { useDispatch } from 'react-redux';
import { openModal } from '../../Assets/Slices/modalSlice.js';
import ContactImage from '../../Assets/img/contact-background.png';
import useMobileBreakPoint from '../../Assets/Helpers/useMobileBreakPoint.jsx';

const Contact = () => {
	const isMobileView = useMobileBreakPoint(768);
	const dispatch = useDispatch();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [comment, setComment] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append('name', name);
		formData.append('email', email);
		formData.append('comment', comment);

		// Send the form data to FormSubmit.co
		fetch('https://formsubmit.co/ajax/922392ae59f88289f576f0003fbc7d5b', {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				data.success === 'true'
					? (() =>
							dispatch(
								openModal({
									modalType: 'success',
									dataObject: {
										modalTitle: 'Contact form submitted successfully!',
										modalText: 'Thanks!!!',
									},
								}),
								setName(''),
								setEmail(''),
								setComment('')
							))()
					: dispatch(
							openModal({
								modalType: 'fail',
								dataObject: {
									modalTitle: 'Contact form submittion failed.',
									modalText: data.message,
								},
							})
					  );
			})
			.catch((error) => {
				dispatch(
					openModal({
						modalType: 'fail',
						dataObject: {
							modalTitle: 'Error submitting contact form.',
							modalText: error.toString(),
						},
					})
				);
			});
	};

	return (
		<styled.ContactFormWrapper isMobileView={isMobileView}>
			{isMobileView ? (
				<></>
			) : (
				<styled.ContactImage src={ContactImage}></styled.ContactImage>
			)}

			<styled.ContactForm isMobileView={isMobileView} onSubmit={handleSubmit}>
				<styled.FormTitle>Contact Us</styled.FormTitle>
				<styled.FormLabel htmlFor='name'>Name</styled.FormLabel>
				<styled.FormInput
					type='text'
					id='name'
					name='name'
					placeholder='Name'
					value={name}
					onChange={(e) => setName(e.target.value)}
				></styled.FormInput>
				<styled.FormLabel htmlFor='email'>Email</styled.FormLabel>
				<styled.FormInput
					type='text'
					id='email'
					name='email'
					placeholder='Email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				></styled.FormInput>
				<styled.FormLabel htmlFor='comment'>Comment</styled.FormLabel>
				<styled.FormTextArea
					id='comment'
					name='comment'
					placeholder='Write your comment here...'
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				></styled.FormTextArea>
				<input
					type='hidden'
					name='_autoresponse'
					value={`Thank you for your intrest, ${name}. We'll get back to you as soon as we can. We look forward to speaking with you.`}
				></input>
				{!name || !email || !comment ? (
					<styled.SubmitButton
						type='none'
						disabled
						style={{ textDecoration: 'line-through' }}
					>
						Submit
					</styled.SubmitButton>
				) : (
					<styled.SubmitButton type='submit'>Submit</styled.SubmitButton>
				)}
			</styled.ContactForm>
		</styled.ContactFormWrapper>
	);
};
export default Contact;
