import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	open: false,
	itemCount: 0,
	itemObjectArray: [],
	cartTotal: 0,
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		openCart: (state) => {
			state.open = true;
		},
		closeCart: (state) => {
			state.open = false;
		},
		addItem: (state, action) => {
			// use productType for sales tracking in the future
			const { title, product, quantity, size, productType } = action.payload;
			const existingProductIndex = state.itemObjectArray.findIndex(
				(item) => item.id === product.id && item.size === size
			);

			if (existingProductIndex !== -1) {
				const updatedItemObjectArray = [...state.itemObjectArray];
				updatedItemObjectArray[existingProductIndex] = {
					...updatedItemObjectArray[existingProductIndex],
					quantity:
						updatedItemObjectArray[existingProductIndex].quantity + quantity,
				};

				return {
					...state,
					itemObjectArray: updatedItemObjectArray,
					cartTotal: state.cartTotal + product.price * quantity,
					itemCount: state.itemCount + quantity,
				};
			} else {
				const newItemObjectArray = [
					...state.itemObjectArray,
					{
						title,
						...product,
						quantity,
						size,
					},
				];

				return {
					...state,
					itemObjectArray: newItemObjectArray,
					cartTotal: state.cartTotal + product.price * quantity,
					itemCount: state.itemCount + quantity,
				};
			}
		},
		modifyItemObject: (state, action) => {
			const { product, size, quantity } = action.payload;
			const existingProductAndSize = state.itemObjectArray?.find(
				(item) =>
					item.id === product.id && item.size === size && item.size !== null
			);
			const existingProductNoSize = state.itemObjectArray?.find(
				(item) => item.id === product.id && item.size === null
			);

			//use case for quantity modifications
			if (quantity && existingProductAndSize) {
				existingProductAndSize.quantity += quantity;
				state.itemCount += quantity;
				state.cartTotal += quantity * product.price;
			}

			if (quantity && existingProductNoSize) {
				existingProductNoSize.quantity += quantity;
				state.itemCount += quantity;
				state.cartTotal += quantity * product.price;
			}

			//add more use cases below
		},
		removeItem: (state, action) => {
			const { product, size } = action.payload;
			const existingProduct = state.itemObjectArray.find(
				(item) => item.id === product.id && item.size === size
			);

			if (existingProduct) {
				const itemIndexToRemove = state.itemObjectArray.findIndex(
					(item) => item.id === existingProduct.id && item.size === size
				);

				state.itemObjectArray.splice(itemIndexToRemove, 1);
				state.itemCount -= existingProduct.quantity;
				state.cartTotal -= existingProduct.price * existingProduct.quantity;
			}
		},
		clearCart: (state) => {
			state.itemObjectArray = [];
			state.itemCount = 0;
			state.cartTotal = 0;
		},
	},
});

export const {
	openCart,
	closeCart,
	addItem,
	modifyItemObject,
	removeItem,
	clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
