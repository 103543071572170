import * as styled from './styles.js';

//modalTypes: success, fail, cancel, and error

const ModalContent = ({ modalType, dataObject, onClose }) => {
	return (
		<styled.ModalContent modalType={modalType}>
			<styled.ModalTitle modalType={modalType}>
				{dataObject?.modalTitle}
			</styled.ModalTitle>
			<styled.ModalText>{dataObject?.modalText}</styled.ModalText>
			<styled.ButtonWrapper>
				<styled.ModalSelection modalType={modalType} onClick={() => onClose()}>
					Confirm
				</styled.ModalSelection>
			</styled.ButtonWrapper>
		</styled.ModalContent>
	);
};
export default ModalContent;
