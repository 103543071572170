import styled from 'styled-components';

//modalTypes: success, fail, cancel, and error
//colors: success, danger, and caution   all have Opaque variants

export const ModalContent = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 5px 10px;

	/* background-color: ${(props) =>
		props.modalType === 'success'
			? (props) => props.theme.colors.successOpaque
			: (props) =>
					props.modalType === 'fail'
						? (props) => props.theme.colors.dangerOpaque
						: (props) =>
								props.modalType === 'cancel'
									? (props) => props.theme.colors.cautionOpaque
									: (props) =>
											props.modalType === 'error'
												? (props) => props.theme.colors.cautionOpaque
												: (props) => props.theme.colors.whiteOpaque}; */

	border: 3px solid
		${(props) =>
			props.modalType === 'success'
				? (props) => props.theme.colors.success
				: (props) =>
						props.modalType === 'fail'
							? (props) => props.theme.colors.danger
							: (props) =>
									props.modalType === 'cancel'
										? (props) => props.theme.colors.caution
										: (props) =>
												props.modalType === 'error'
													? (props) => props.theme.colors.danger
													: (props) => props.theme.colors.white};
`;

export const ModalTitle = styled.h2`
	color: ${(props) =>
		props.modalType === 'success'
			? (props) => props.theme.colors.success
			: (props) =>
					props.modalType === 'fail'
						? (props) => props.theme.colors.danger
						: (props) =>
								props.modalType === 'cancel'
									? (props) => props.theme.colors.caution
									: (props) =>
											props.modalType === 'error'
												? (props) => props.theme.colors.danger
												: (props) => props.theme.colors.white};
`;

export const ModalText = styled.p`
	color: ${(props) => props.theme.colors.offBlack};
`;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;

	margin: 5px 0;

	transition: all 0.35s ease-out;

	:hover {
		transform: scale(1.1);
	}
`;

export const ModalSelection = styled.button`
	padding: 5px;

	border-radius: 5px;

	background-color: ${(props) =>
		props.modalType === 'success'
			? (props) => props.theme.colors.successOpaque
			: (props) =>
					props.modalType === 'fail'
						? (props) => props.theme.colors.dangerOpaque
						: (props) =>
								props.modalType === 'cancel'
									? (props) => props.theme.colors.cautionOpaque
									: (props) =>
											props.modalType === 'error'
												? (props) => props.theme.colors.cautionOpaque
												: (props) => props.theme.colors.whiteOpaque};

	transition: all 0.35s ease-out;

	:hover {
		border-radius: 0px;
	}
`;
