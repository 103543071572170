import { Link } from 'react-router-dom';
import AddToCartButton from '../../Cart/AddToCartButton/AddToCartButton.jsx';
import * as styled from './styles.js';
import { formatPrice } from '../../../Assets/Helpers/formatPrice.js';
import { useState } from 'react';

const ShopCard = ({ id, desc, img01, img02, price, name, slug, size }) => {
	const [selectedSize, setSelectedSize] = useState(size ? 'M' : null);

	const linkStyles = {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	};

	const handleSizeChange = (e) => {
		setSelectedSize(e.target.value);
	};

	return (
		<styled.ShopCard>
			<Link to={`/shop/${slug}`} style={linkStyles}>
				<>
					<styled.ShopCardHeader>
						<styled.ShopCardTitle>{name}</styled.ShopCardTitle>
						<styled.ShopCardPrice>${formatPrice(price)}</styled.ShopCardPrice>
					</styled.ShopCardHeader>
					<styled.ShopCardImg src={img01} hoverImg={img02} alt={name} />
				</>
			</Link>
			{size ? (
				<styled.SizeOptions>
					<label>Select Size:</label>
					<select onChange={handleSizeChange} value={selectedSize}>
						<option value='S'>S - Small</option>
						<option value='M'>M - Medium</option>
						<option value='L'>L - Large</option>
						<option value='XL'>XL - Extra Large</option>
						<option value='XXL'>XXL - Extra Extra Large</option>
					</select>
				</styled.SizeOptions>
			) : null}
			<styled.ATCButtonWrapper>
				<AddToCartButton productId={id} size={selectedSize} quantity={1} />
			</styled.ATCButtonWrapper>
		</styled.ShopCard>
	);
};
export default ShopCard;
