import { Outlet } from 'react-router-dom';
import * as styled from './styles.js';

const ShopPage = () => {
	return (
		<styled.ShopWrapper>
			<Outlet />
		</styled.ShopWrapper>
	);
};
export default ShopPage;
