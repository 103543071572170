import styled from 'styled-components';

export const ReviewCardWrapper = styled.div`
	text-align: center;
	margin-bottom: 15px;

	& > * {
		margin-bottom: 5px;
		color: ${(props) => props.theme.colors.black};
	}
`;

export const ReviewTitle = styled.h3``;

export const UserName = styled.h5``;

export const Date = styled.p``;

export const ReviewStatement = styled.p`
	text-align: center;
`;

export const Stars = styled.div``;
