import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cartReducer from './Assets/Slices/cartSlice';
import searchReducer from './Assets/Slices/searchSlice';
import homeReducer from './Assets/Slices/searchSlice';
import productsReducer from './Assets/Slices/productsSlice';
import reviewsReducer from './Assets/Slices/reviewsSlice';
import modalReducer from './Assets/Slices/modalSlice';
import { apiSlice } from './Assets/Slices/apiSlice';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	whitelist: ['cart'],
};

const rootReducer = combineReducers({
	cart: cartReducer,
	search: searchReducer,
	home: homeReducer,
	products: productsReducer,
	reviews: reviewsReducer,
	modal: modalReducer,
	[apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(apiSlice.middleware),
});

export let persistor = persistStore(store);
