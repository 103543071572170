import Featured from '../Featured/Featured.jsx';
// import StaffCard from './StaffCard/StaffCard.jsx';
import * as styled from './styles.js';
import HeroImg from '../../Assets/img/About.jpg';

const AboutPage = () => {
	return (
		<styled.AboutWrapper>
			<styled.AboutLanding>
				<styled.AboutLandingHeroImg
					title='About'
					alt='A high contrast expsure of people standing on top of a moutain with a blue sky in the background.'
					src={HeroImg}
				></styled.AboutLandingHeroImg>
			</styled.AboutLanding>
			<styled.MissionSatementWrapper>
				<styled.AboutSectionTitle>About Us</styled.AboutSectionTitle>
				<styled.MissionStatement>
					Everything Unique Clothing Co. is a unique clothing company dedicated
					to creating high-quality apparel that celebrates and supports local
					artisans and communities. Our mission is to produce stylish and
					sustainable fashion pieces while fostering a sense of local pride and
					community engagement.
				</styled.MissionStatement>
				<styled.AboutSectionTitle>Mission:</styled.AboutSectionTitle>
				<styled.MissionStatement>
					"To create meaningful fashion that supports local communities,
					preserves cultural heritage, and promotes sustainable living."
				</styled.MissionStatement>
				<styled.AboutSectionTitle>Vision:</styled.AboutSectionTitle>
				<styled.MissionStatement>
					Everything Unique Clothing Co. envisions a world where fashion not
					only looks good but does good, fostering stronger, more sustainable
					communities through every stitch.
				</styled.MissionStatement>
			</styled.MissionSatementWrapper>

			{/* <styled.StaffWrapper>
				<StaffCard img={''} />
				<StaffCard img={''} />
				<StaffCard img={''} />
			</styled.StaffWrapper> */}

			<styled.FeaturedWrapper>
				<Featured />
			</styled.FeaturedWrapper>
		</styled.AboutWrapper>
	);
};
export default AboutPage;
