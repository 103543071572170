import { styled } from 'styled-components';

export const BannerContentWrapper = styled.div`
	width: 100vw;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 5px 0;
`;

export const BannerContent = styled.span`
	text-align: center;
`;
